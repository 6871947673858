import qs from 'qs';
import axios from  'axios';

console.log(process.env);

const CLIENT_ID = process.env.CLIENT_ID
const ROOT_URL = process.env.ROOT_URL


export default {
  login() {
    const queryString =  {
      client_id: CLIENT_ID,
      response_type: 'token'
    };
    window.location = `${ROOT_URL}/oauth2/authorize?${qs.stringify(queryString)}`;
  }, 

  async fetchImages(token) {
    const headers = { Authorization: `Bearer ${token}`};
    const response = await axios.get(`${ROOT_URL}/3/account/me/images`, {
      headers
    })
    return response;
  }
}

