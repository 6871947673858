<template>
  <div>
    <img v-for="image in getImages" :src="image.link" :key="image.id"/>d
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'ImageList',
    computed: { ...mapGetters(['getImages'])},
    methods: { ...mapActions([ 'fetchImages' ])},
    created() {this.fetchImages();}
  };
</script>

<style scoped>

</style>