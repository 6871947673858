import VueRouter from 'vue-router'
import AuthHandler from '../components/AuthHandler'
import ImageList from '../components/ImageList'
import UploadForm from '../components/UploadForm'

const router =  new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', component: ImageList },
    { path: '/upload', component: UploadForm },
    { path: '/oauth2/callback', component: AuthHandler},
  ]
});

export {
  router
};