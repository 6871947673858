import qs from 'qs'
import imgurAPI from '../../api/imgur';
import { router } from '../../routers/router';

const state = {
  token: window.localStorage.getItem('imgur_token')
};

const getters = {
  isLoggedIn: state => !!state.token
};

const actions = {
  logout({ commit }){ 
    commit('setToken', null);
    window.localStorage.removeItem('imgur_token');
   },

  login(){ imgurAPI.login();},

  finalizeLogin({ commit }, hash){
    const query = qs.parse(hash.replace('#', ''));
    commit('setToken', query.access_token);
    window.localStorage.setItem('imgur_token', query.access_token);
    //  Redirect to page root.
    router.push('/');
  },
};

const mutations = {
  setToken: (state, token) => {state.token = token;}
};

export default {
  state,
  getters,
  actions,
  mutations
}


