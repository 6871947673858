<template>
  <div>
    Upload Form
  </div>
</template>

<script>
  export default {
    name: 'UploadForm'
  };
</script>

<style scoped>

</style>