import imgurApi from '../../api/imgur';

const state = {
  images: []
}

const getters = {
  getImages: state => state.images
}

const actions = {
  async fetchImages ({ rootState, commit }) {
    const { token } = rootState.auth;
    const response = await imgurApi.fetchImages(token);
    commit('setImages', response.data.data);
  },
  uploadImages: () => { /**/ },
}

const mutations = {
  setImages: (state, images) => {state.images = images}
}

export default {
  state, 
  getters,
  actions, 
  mutations
}

